import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/alanfung/Documents/Projects/Code/gatsby-portfolio/src/templates/journal.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1280px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "62.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAADq0lEQVQ4yx2Sf1DTdRjHP3ReZf909lfXpWFwqHhlZZl0pIHoVWbn4VXKlWGmBYksUokIMFSQ4a9DLrQheCoImkigMMQpyI8JjoFzCIiwNmFjA4ajDSZse/WV5+6556/ndc/7eb/Frb2hNPwRQmP6ctqyV6GRh9NTloylU8m4uYkpexWe8Ubcdh2Tjw2M9Teye/tG5voHEbT4bUJCPqCiJBvv8HW85mqENjuc9kMS6GAorVkr6KmWY+ur44mjj8F7Z7CqZUz2SgtDufjc9UxNObEP6FDIEwkLW0NoxAbeWLqCNtVJsFYi2g+H03E0gtbMEDrLUxnuu8m00wzY0VT9xv2yr/AZ9+EdTMRrywTvA5yuCWymdlRnU9gRu4uYlFM01xyHyRqERv4hLZnLaDr6OWZ9OZNWSeZgKY6aZfRf+IhR9c94HiTB8AEwp0nyy3laBqOR4QdVPKrLYPOG9SwICEBxSIao3/seV38JouWsDJetjUeqGLTpgrY0Qe9hgeXScuyqKEyXIzHVxIPPPgN0uv5De+cWmBRY6pNY/W4gQvghznw3j/xv/dEpj/HEWktz1iuoEwXNybMw5Ag6Mvw4/8NclFsXcTPuHe5fyZsB+nwe2rW3GdGcgp4cRhsyWL9iISL3i5fIiXqNrnoFTuM/1CbNQp0kUKf4UfCNIPEtQepigfL7YDS7V3Jk5WwG9bdmoHfbb2NrOY3rRirupkzc9fsQlxOCOffTQrRX5Litjdw7/zGqXYLsTwVr5wjSlghyw54lf/0cLm2ez/HPXpXcvzYD1GkbGW3N50lTOhPqTCYb9yOupSylLD6QGwUyJswNOIdaGVBGckACfB0gOBTmR97a5zm8ejayNwXFv697+kEcdiN3W2pxtx/D2ymXzD+Ot/8EonL3Yq4mBFKcvIYhfTFOw1k8jntc/CuFVS8K4pbMIib4GbbME+xc/gIGXQW+aQu9XWp61SV4biczpd2PR5+Fp+sIoujHQMpki1BEz6fiRAKugWombXewSOHes3Ut7z8niHxZsGOlP9WF6dJ1I4yYO+jUKBmp3oOjMprx2p04rsczrkpAFG4LpCg2mL+lPx788nWuFO5lzHid6VEN7jEdp7LiUOzZyLhFh9dl5LE0Dfo6DOW/8u+fEfSfWIcpPxLz6U1Yz0UjircvoDR2ERd2SB0bwJGoAAoObKOruQhrd5UU9DtM2LTYHjZg0t+gT11Kb0kc3TkRdOd+wsO8dZjPbWKoZAu2i9v5HzZW6JbaUyEuAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Baked Chicken",
          "title": "Baked Chicken",
          "src": "/static/8dfd7477a2202da0078218f2c2571f47/21b4d/featured-image.png",
          "srcSet": ["/static/8dfd7477a2202da0078218f2c2571f47/e17e5/featured-image.png 400w", "/static/8dfd7477a2202da0078218f2c2571f47/5a190/featured-image.png 800w", "/static/8dfd7477a2202da0078218f2c2571f47/21b4d/featured-image.png 1280w"],
          "sizes": "(max-width: 1280px) 100vw, 1280px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`This is really a great and easy recipe. The prep time is minimal and its not like we don't have a lot of time on our hands right now.. right? The main problem is getting ingredients - so hopefully you have something similiar in your house.`}</p>
    <h2>{`Prep Times`}</h2>
    <p>{`10 minute prep | 1 hour marinate | 45 - 50 minutes cook time`}</p>
    <h2>{`What We'll Need`}</h2>
    <ul>
      <li parentName="ul">{`8 Chicken Thighs`}</li>
      <li parentName="ul">{`2 TBSP Salt`}</li>
      <li parentName="ul">{`2 TBSP Pepper`}</li>
      <li parentName="ul">{`4 TBSP Olive Oil`}</li>
      <li parentName="ul">{`1 TBSP Sugar`}</li>
    </ul>
    <p>{`To start, preheat your oven to 400`}{`°`}{`F.`}</p>
    <p>{`While that is going, you'll want to pat dry your chicken and toss them into a bowl. Take the oil and coat the chicken. Now your dry ingredients, pre-mix them together in a seperate container and spread onto the chicken, try to evenly distribute it as much as possible. Leave the chicken in the bowl covered for an hour.`}</p>
    <p>{`Get your sheet pan ready - line it with foil and place a wire rack on top if you have it. If not, no big deal.`}</p>
    <p>{`Now go do something for an hour.`}</p>
    <h2>{`Line'em up!`}</h2>
    <p>{`Place the chicken thighs - skin side up on the rack and spread them apart evenly. I generally do about a half inch apart if I can, just make sure they aren't touching. Its not that kind of a party. Get the chicken into the oven, hopefully in the hottest part of your oven, for me thats actually the top rack near the back.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1280px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEDAv/EABYBAQEBAAAAAAAAAAAAAAAAAAIBBP/aAAwDAQACEAMQAAABorPKsjDf/8QAGBAAAwEBAAAAAAAAAAAAAAAAAAECIDH/2gAIAQEAAQUCq0KzuP/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPwHYr//EABoQAAICAwAAAAAAAAAAAAAAAAABESAhYaH/2gAIAQEABj8C2ZfCVT//xAAaEAEBAAIDAAAAAAAAAAAAAAABABFBIWFx/9oACAEBAAE/IQZBTxNk5OiCHRbizf/aAAwDAQACAAMAAAAQty//xAAWEQEBAQAAAAAAAAAAAAAAAAABACH/2gAIAQMBAT8QRtv/xAAXEQADAQAAAAAAAAAAAAAAAAAAESEx/9oACAECAQE/EERGj//EABkQAQADAQEAAAAAAAAAAAAAAAEAESExUf/aAAgBAQABPxCk0PC+wLEUx1wwDb0iruLSKFpn/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Meme",
          "title": "Meme",
          "src": "/static/73033522d086b43be9f3a7e747f159a9/eea4a/baked-chicken-02.jpg",
          "srcSet": ["/static/73033522d086b43be9f3a7e747f159a9/066f9/baked-chicken-02.jpg 400w", "/static/73033522d086b43be9f3a7e747f159a9/4b190/baked-chicken-02.jpg 800w", "/static/73033522d086b43be9f3a7e747f159a9/eea4a/baked-chicken-02.jpg 1280w"],
          "sizes": "(max-width: 1280px) 100vw, 1280px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1280px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "62.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAADr0lEQVQ4yw2T6U/bBQCGf4ky3cFYwIoEGO2QSyhQ2cptYYNxH6VcpR0tlGN0MCiDcgQwsAHjmgNlIAHSDjYumRgEFjePoTi/sNgRdSZqsjlN1GgW/WCyDB/7B7x58zx5X6Eg/ihDWa+xZfTn8xopN0qPsd5VxvJYLzOjA9y1mvl5LI31oWomB5voNxXRfOok55OOsFTkTt+peJqrynmrqYyJAglCuyGDlfp0/h2MgYVUsOrgmpFf70/y8P0uWFbDXDRYsvlrvpWNuXfY6c6EkUierqbxdCKWnwZ0DJ9R0qEUI5iyj7HZnMTueBa7U3H2sJ4HvTlsWiv5tiUExsJ5NiGFFXuRpQLbVA0fG+T81xQM8wmwXg7Xq5jVBtGRE4RQXxjL7W4VP5oDuaM+yJUEN/oKo9i+1cNihZR/Otz4u9eD7fpAbhTI2BhvZO6cipsqF7ZqfbENFPB42sSFslSaMkMR+ksiuNkRy706d7466860xgNrWyqPbFf41NrAWk0oD0yuLKS9ylRdLp9d72JzqoG5ymgsKgmDGYdZbNWy2lfPUKsJYVQdiM3sz2/t3nYEO/LMGzzpDeHrWQPf353myxYFvxhehIvhfGNXc+uynk+qgnikdmK31pXfTS7cr3BjUxNAp0qO0FcYjK3Rnyednuy+G8SzPgmPG0VsXVLxUaeKO8rneNjoybbWgaUMV5b6y6hNj6DFX2CnVITtzEus5jkypHAmyssRoS7RjY1qCX8Oh/FHt4xFgzs9MQLXzsZxqTKZXF8nLscdoEcqcCEvAkuLkunqOJRST4peEbDEC7yteJ6m3EiiZX4I5ScOM2mOY75dw4I+AKPMiZJwMeOtKm5P1TNgn0O62JkM8UEsKYewFe9lR+PId8YIzsd5kyISKA17mYYSJbnHZQhm7XGG24qZKo3FkuFCW/AeykNEjJTJGT2XzEh7NXlpyejknnyhE/FDjZgPk/Zwr9iXtVwfEl73IiU+Em1qLOqUKAR9TjKD2lCuZoqYSBFxXr6P05IXKInxorm6kjcbGzEWJZMn88Acup/RWCf6ZQLdYQ6YjjqjyVagzc1AX5iPucqAUJB6gppECUPxB2gIdKDcW6BE4kCtTsfayjrLszP2y7XQpktC47efcj8HdAH7SA/yoDj7JDptIRplGgZ1PnpVNoIuK4GIADHKI3vJ8znE6WgfhuuNLMy8h3Vsgg9mr2IdHqSzzkhFfg6KYH8S5aF2TAUJMdHER0USGRKI1EdMgJcr/wOxNEsDkkWPJgAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Finished Chicken",
          "title": "Finished Chicken",
          "src": "/static/cee7f8737b90b3a2d4ffffcbec288001/21b4d/baked-chicken-03.png",
          "srcSet": ["/static/cee7f8737b90b3a2d4ffffcbec288001/e17e5/baked-chicken-03.png 400w", "/static/cee7f8737b90b3a2d4ffffcbec288001/5a190/baked-chicken-03.png 800w", "/static/cee7f8737b90b3a2d4ffffcbec288001/21b4d/baked-chicken-03.png 1280w"],
          "sizes": "(max-width: 1280px) 100vw, 1280px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h3>{`How did it turn out?! Hopefully well!`}</h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      